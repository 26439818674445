import debug from "./debugUtils";
import { Metrics, newMetricsWithContext } from "./metricsUtils";
import { NativeAPI, getDeviceDetails } from "./deviceUtils";
import { getCurrentLocale } from "./localeUtils";
import { AapiCsrfData, getAapiCsrfData } from "./aapiCsrfUtils";

let requestDomain = "";
export const setRequestDomain = (domain: string) => {
    debug.log(`setRequestDomain: ${domain}`);
    requestDomain = domain;
};

let useRequestDomainOnRequests = false;
export const setUseRequestDomainOnRequests = (input: boolean) => {
    useRequestDomainOnRequests = input && getDeviceDetails().isIOS;
};

const isValidAsin = (asin: string): boolean => /[A-Z0-9]{10}/.test(asin);
export const throwOnInvalidAsin = (asin: string): void => {
    if (!isValidAsin(asin)) {
        throw new Error("Invalid ASIN");
    }
};

export type PerfWrapper<Type> = {
    requestUrl: string;
    networkElapsed: number;
    parseElapsed: number;
    size: number;
    result: Type;
    isError?: boolean;
    error?: string;
};

export const perfToString = (perfWrapper: PerfWrapper<any>, includeResult = false) => {
    return `
${perfWrapper.requestUrl}

Network: ${perfWrapper.networkElapsed}ms
Size: ${perfWrapper.size}
${perfWrapper.isError ? `Error: ${perfWrapper.error}` : ""}
${includeResult ? `\nResult:\n${JSON.stringify(perfWrapper.result)}` : ""}
`;
};

const requestToString = (args: HttpRequestArgs): string => {
    const queryParamsString = args.queryParams
        ? `?${Object.entries(args.queryParams)
              .map(([key, value]) => `${key}=${value}`)
              .join("&")}`
        : "";
    const headersString = args.headers
        ? `\n\nHeaders:\n${Object.entries(args.headers)
              .map(([key, value]) => `${key}: ${value}`)
              .join("\n")}`
        : "";
    const bodyString = args.body ? `\n\nBody:\n${args.body}` : "";
    return `${args.method ?? "GET"} ${args.path}${queryParamsString}${headersString}${bodyString}`;
};

export type HttpRequestArgs = {
    path: string;
    method?: string;
    queryParams?: { [key: string]: string };
    headers?: { [key: string]: string };
    body?: string;
    preferNative?: boolean;
    parseResponseAsJson?: boolean;
};

const callNativeHttpRequestBridgeAPIWithPerf = async (args: HttpRequestArgs, metrics?: Metrics): Promise<PerfWrapper<any>> => {
    const networkStart = Date.now();
    let response;
    let isError = false;
    let error;
    const parseAsJson = args.parseResponseAsJson !== false;
    try {
        response = await NativeAPI.httpRequest(args);
        metrics?.recordOperationalMetric("httpRequestError", 0);
    } catch (e) {
        error = `${e}`;
        debug.log(error);
        isError = true;
        metrics?.recordOperationalMetric("httpRequestError", 1.0, error);
    }
    const networkElapsed = Date.now() - networkStart;
    metrics?.recordOperationalMetric("httpRequestLatency", networkElapsed);
    const parseStart = Date.now();
    let result;
    try {
        if (parseAsJson) {
            result = response ? JSON.parse(response) : "";
            metrics?.recordOperationalMetric("jsonParseError", 0);
        } else {
            result = response || "";
        }
    } catch (e) {
        error = `${e}`;
        debug.log(error);
        isError = true;
        metrics?.recordOperationalMetric("jsonParseError", 1.0);
    }
    const parseElapsed = Date.now() - parseStart;
    const size = response ? response.length : 0;
    metrics?.recordOperationalMetric("jsonResponseSize", size);
    return {
        requestUrl: requestToString(args),
        networkElapsed,
        parseElapsed,
        size,
        isError,
        result,
        error,
    };
};

const callNativeHttpRequestBridgeAPI = (args: HttpRequestArgs, metrics: Metrics, includeResult = false): Promise<any> => {
    const responseWithPerf = callNativeHttpRequestBridgeAPIWithPerf(args, metrics);
    if (debug.isDebugLoggingEnabled) {
        responseWithPerf.then(it => debug.log(`${perfToString(it, includeResult)}`));
    }
    return responseWithPerf.then(it => it.result);
};

export const fetchAsinMetadataAapiWithPerf = async (asins: string[]): Promise<PerfWrapper<AapiAsinMetadata[]>> => {
    const path = `/kindle-dbs/qv/data`;
    const locale = getCurrentLocale();
    const queryParams = {
        version: "aapibb",
        asins: asins.join(","),
        useDeviceLocale: "true",
        locale, // This is here to ensure cache breaking on locale changes
    };
    const headers = {
        "Accept-Language": locale,
        "Cache-Control": "max-age=120", // 2 minutes = 60s * 2 = 120s
    };
    const isBatch = asins.length > 1;
    const metrics = newMetricsWithContext(
        isBatch ? "fetchAsinMetadataAapi.batch" : "fetchAsinMetadataAapi",
        isBatch ? "BATCH" : asins[0],
    );
    if (isBatch) {
        metrics.recordBehavioralMetric("asinCount", asins.length);
    }
    return callNativeHttpRequestBridgeAPIWithPerf({ path, queryParams, headers }, metrics);
};

export type AsinOffers = {
    httpStatusCode: number;
    status: string;
    resources?: [{ personalizedActionOutput?: { personalizedActions?: PersonalizedAction[]; }; }];
};

export const fetchAsinOffers = async (asin: string): Promise<AsinOffers> => {
    throwOnInvalidAsin(asin);
    const path = `/api/bifrost/offers/v1/${asin}`;
    const headers = {
        "x-client-id": "kindle-reach-qv",
        "Accept": "application/json",
        "Cache-Control" : "no-cache",
    };
    const metrics = newMetricsWithContext("fetchAsinOffers", asin);
    return callNativeHttpRequestBridgeAPI({ path, headers, preferNative: true }, metrics);
};

export enum BuyOrPreorderType {
    BUY, PREORDER,
}

type BiFrostAcquisitionResponseState = {
    developerMessage?: string;
    localizedMessage?: string;
    links?: [ { url?: string; rel?: string; method?: string; } ],
    responseCode?: string;
    resultCode?: string;
};

type BiFrostAcquisitionResponse = {
    httpStatusCode?: number;
    status?: string;
    errorResult?: BiFrostAcquisitionResponseState;
    resources: [ {
        acquisitionId?: null,
        states?: [BiFrostAcquisitionResponseState];
    } ];
};

export type BuyOrPreorderOptions = {
    url: string;
    type: BuyOrPreorderType;
    isAFR: boolean;
    body: {
        csrf?: string;
        botmBuyButton?: string;
        botmBuyButtonDisplayedPrice?: string;
        botmBuyButtonDisplayedPriceCurrencyCode?: string;
        botmOfferType?: string;
        payment?: { mode?: string; billingPlatform?: string; };
        items: [{
            audibleNarration?: {
                addNarration: "WFV",
                audibleAsin: string;
                audibleOurPrice: string;
            };
            optional?: { botmSignupForEmail?: string; };
            action?: {
                asin: string;
                actionType: string;
                botmType?: string;
                program?: { name?: string; };
                displayedPrice?: { value?: string; currency?: string; };
            };
        }];
    };
};

export const buyOrPreorderBiFrost = async (asin: string, options: BuyOrPreorderOptions): Promise<BiFrostAcquisitionResponse> => {
    const path = `${options.url}`;
    const headers = { "x-client-id": "kindle-reach-qv", "Content-Type": "application/json" };
    const queryParams = { csrf: options?.body?.csrf ?? "" }; // TODO: Do we need this in the query params?
    const metrics = newMetricsWithContext(`buyOrPreorder.${options.type}`, asin);
    return callNativeHttpRequestBridgeAPI({ path, method: "POST", queryParams, headers, body: JSON.stringify(options.body) }, metrics, true);
};

export const redeemAsinOffer = async (asin: string, action: PersonalizedAction): Promise<BiFrostAcquisitionResponse> => {
    const path = `/api/bifrost/acquisitions/v1/actions/${action.actionId}`;
    const headers = { "x-client-id": "kindle-reach-qv", "Content-Type": "application/json" };
    const queryParams = { csrf: action.csrfToken };
    const body = { csrf: action.csrfToken, items: [{ action: { asin: asin, actionType: action.actionType } }] };
    const metrics = newMetricsWithContext(`redeemAsinOffer.${action.actionType}`, asin);
    return callNativeHttpRequestBridgeAPI({ path, method: "POST", queryParams, headers, body: JSON.stringify(body), preferNative: true }, metrics);
};

export const returnAsinBiFrost = async (asin: string) => {
    debug.log(`returnAsinBiFrost ${asin}`);
    const path = requestDomain && useRequestDomainOnRequests
        ? `https://www.${requestDomain}/api/bifrost/returns/v1/asins/${asin}`
        : `/api/bifrost/returns/v1/asins/${asin}`;
    const headers = { "x-client-id": "kindle-reach-qv", "Content-Type": "application/json" };
    const metrics = newMetricsWithContext("returnAsinBiFrost", asin);
    const body = {};
    return callNativeHttpRequestBridgeAPI({ path, method: "POST", headers, preferNative: true, body: JSON.stringify(body) }, metrics);
};

export const fetchCustomerReviews = async (asin: string): Promise<PicassoCustomerReviewsData> => {
    const path = `/kindle-dbs/qv/reviews`;
    const locale = getCurrentLocale();
    const queryParams = {
        asin,
        limit: "5",
        useDeviceLocale: "true",
        locale, // This is here to ensure cache breaking on locale changes
    };
    const headers = {
        "Accept-Language": locale,
        "Cache-Control": "max-age=1800", // 30 minutes = 60s * 30 = 1800s
    };
    const metrics = newMetricsWithContext("fetchCustomerReviews", asin);
    return callNativeHttpRequestBridgeAPI({path, queryParams, headers}, metrics);
};

// TODO: Trim this type further? We only use a couple fields
export type BorrowedProduct = {
    product?: {
        productImages?: {
            images?: [
                {
                    lowRes?: {
                        extension?: string;
                        physicalId?: string;
                        width?: number;
                        region?: string;
                        height?: number;
                    };
                    hiRes?: {
                        extension?: string;
                        physicalId?: string;
                        width?: number;
                        region?: string;
                        height?: number;
                    };
                    variant?: string;
                }
            ];
            altText?: string;
        };
        byLine?: {
            contributors?: [
                {
                    contributor?: {
                        author?: {
                            url?: string;
                        };
                    };
                    roles?: [
                        {
                            displayString?: string;
                            type?: string;
                        }
                    ];
                    name?: string;
                }
            ];
        };
        asin?: string;
        title?: {
            displayString?: string;
        };
    };
    channel?: string;
    asin?: string;
    program?: string;
    loanId?: string;
    status?: string;
};

type GetBorrowsResponse = {
    message: string;
    statusCode: string;
    borrowedProducts?: BorrowedProduct[];
};

export const getBorrows = async (
    asin: string,
    programCode: string,
    channelCode: string
): Promise<GetBorrowsResponse> => {
    const path = `/kindle-dbs/bacchus/api/getLoans`;
    const queryParams = {
        asin,
        program: programCode,
        channel: channelCode,
    };
    const headers = { "Cache-Control" : "no-cache" };
    const metrics = newMetricsWithContext("getBorrows", asin);
    return callNativeHttpRequestBridgeAPI({path, queryParams, headers}, metrics);
};

type WeblabResponse = { [key: string]: string };

export const getWeblabTreatments = async (weblabIds : string[]): Promise<WeblabResponse> => {
    const path = `/kindle-dbs/qv/get-wl-treatment`;
    const metrics = newMetricsWithContext("getWeblabTreatments");
    const headers = { "Cache-Control" : "no-cache" };
    const queryParams = { "weblabs": weblabIds.join(",") };
    return callNativeHttpRequestBridgeAPI({ path, queryParams, headers }, metrics, true);
};

export const getWeblabTreatmentsAndTrigger = async (weblabIds : string[]): Promise<WeblabResponse> => {
    const path = `/kindle-dbs/qv/get-wl-treatment-and-trigger`;
    const metrics = newMetricsWithContext("getWeblabTreatmentsAndTrigger");
    const headers = { "Cache-Control" : "no-cache" };
    const queryParams = { "weblabs": weblabIds.join(",") };
    return callNativeHttpRequestBridgeAPI({ path, queryParams, headers }, metrics, true);
};

export const recordWeblabTrigger = async (weblabIds : string[]): Promise<Record<string, never>> => {
    const path = `/kindle-dbs/qv/record-wl-trigger`;
    const metrics = newMetricsWithContext("recordWeblabTrigger");
    const headers = { "Cache-Control" : "no-cache" };
    const queryParams = { "weblabs": weblabIds.join(",") };
    return callNativeHttpRequestBridgeAPI({ path, queryParams, headers }, metrics, true);
};

export type Wishlist = {
    name: string;
    id: string;
    isDefault: boolean;
    isPrivate: boolean;
};

type GetWishlistsResponse = {
    statusCode: string;
    wishlists: Wishlist[];
};

export const getWishlists = async (): Promise<GetWishlistsResponse> => {
    const metrics = newMetricsWithContext("getWishlists");
    // Do not cache wishlists, as the list will change when new lists are created.
    const headers = { "Cache-Control" : "no-cache" };
    return callNativeHttpRequestBridgeAPI({path: `/kindle-dbs/bacchus/api/getWishlists`, headers: headers}, metrics);
};

type AddToWishlistResponse = {
    statusCode: string;
    response: {
        defaultWishlistId?: string;
        itemAdded: boolean;
        succeeded: boolean;
        resultStatus: string; // "DUPLICATE_ENTRY_ADDED" - already in list, "UNCATEGORIZED" - list deleted, "SUCCEEDED" - added, others?
    };
};

export const addToWishlist = async (asin: string, wishlist: Wishlist): Promise<AddToWishlistResponse> => {
    const path = `/kindle-dbs/bacchus/api/addToWishlist`;
    const queryParams = {
        asin,
        wishlistId: wishlist.id,
    };
    const body = JSON.stringify({
        asin,
        wishlistId: wishlist.id,
    });
    const metrics = newMetricsWithContext("addToWishlist", asin);
    return callNativeHttpRequestBridgeAPI({path, method: "POST", queryParams, body}, metrics);
};

export enum WishlistVisibility {
    PRIVATE = "PRIVATE_WISHLIST",
    PUBLIC = "PUBLIC_WISHLIST",
}

export const fetchAapiCsrfData = async (): Promise<AapiCsrfData> => {
    const path = `/kindle-dbs/qv/csrf`;
    const metrics = newMetricsWithContext("fetchAapiCsrfData");
    const headers = { "Cache-Control" : "no-cache" };
    return callNativeHttpRequestBridgeAPI({path, headers}, metrics);
};

type AapiCreateWishlistResponse = {
    "entity"?: {
        "ref"?: { "resource"?: { "url"?: string } },
        "status"?: any, // status presence indicates a failure
    }
};

type CreateWishlistAapiResponse = {
    created: boolean;
    added: boolean;
};
// Creates a new wishlist and adds the item to it
export const createWishlistAapi = async (asin: string, name: string, visibility: WishlistVisibility): Promise<CreateWishlistAapiResponse> => {
    // Unclear when/if the CSRF token will expire, so allow a force refresh
    let retryWithFreshCsrfToken = false;
    const output = { created: false, added: false };
    const metrics = newMetricsWithContext("createWishlistAapi", asin);
    do {
        const aapiData = await getAapiCsrfData({forceRefresh: retryWithFreshCsrfToken});
        const path = `https://${aapiData.aapiAjaxEndpoint}/api/marketplaces/${aapiData.marketplaceId}/lists`;
        const queryParams = {
            "sif_profile": aapiData.aapiSeigeProfileName || "",
            "listTypes": "AMAZON_LIST",
            "enableSecureEgressDecorator": "true",
        };
        const body = JSON.stringify({
            vendorId: "kindle.qv.createlist",
            title: {
                data: name,
                encryption: { fieldGuard: { handle: "siege.a2z.com/fg/dt/Glaggregator.SecureIngressFields" } }
            },
            privacy: visibility === WishlistVisibility.PUBLIC ? "PUBLIC" : "PRIVATE",
            listTypeDetails: { amazonList: {} }
        });
        const headers: { [key: string]: string } = {
            "Accept": 'application/vnd.com.amazon.api+json; type="lists.create/v1"',
            "Accept-Language": "en-US", // AAPI will throw a 406 if using a language code it doesn't recognize.
            "Content-Type": 'application/vnd.com.amazon.api+json; type="lists.create.request/v1"',
            "x-api-csrf-token": aapiData.aapiCsrfToken || "",
            "Origin": `https://www.${requestDomain}`,
        };
        if (aapiData.encryptedSlateToken) {
            headers["x-amzn-encrypted-slate-token"] = aapiData.encryptedSlateToken;
        }
        const responseWithPerf: PerfWrapper<AapiCreateWishlistResponse> = await callNativeHttpRequestBridgeAPIWithPerf({ method: "POST", path, queryParams, body, headers}, metrics);
        if (debug.isDebugLoggingEnabled) {
            debug.log(`${perfToString(responseWithPerf, true)}`);
        }
        if (responseWithPerf.isError) {
            if (retryWithFreshCsrfToken) {
                metrics.recordOperationalMetric("AapiCreateWishlist.success", 0);
                break;
            }
            retryWithFreshCsrfToken = true; // try again with a fresh CSRF token
            continue;
        }
        const listRefUrl = responseWithPerf.result?.entity?.ref?.resource?.url;
        const status =  responseWithPerf.result?.entity?.status;
        if (listRefUrl && !status) {
            metrics.recordOperationalMetric("AapiCreateWishlist.success", 1);
            output.created = true;
            output.added = await addToWishlistAapi(asin, listRefUrl);
        }
        break;
    } while (true);
    return output;
};

type AapiAddToWishlistResponse = {
    "entity"?: {
        "ref"?: { "resource"?: { "url"?: string } },
        "status"?: any, // status presence indicates a failure
    }
};

const addToWishlistAapi = async (asin: string, listRefUrl: string): Promise<boolean> => {
    // Unclear when/if the CSRF token will expire, so allow a force refresh
    let retryWithFreshCsrfToken = false;
    let output = false;
    const metrics = newMetricsWithContext("addToWishlistAapi", asin);
    do {
        const aapiData = await getAapiCsrfData({forceRefresh: retryWithFreshCsrfToken});
        const path = `https://${aapiData.aapiAjaxEndpoint}${listRefUrl}/items/add-product`;
        const body = JSON.stringify({
            vendorId: "kindle.qv.addToList",
            desiredQuantity: 1,
            productRef: `/api/marketplaces/${aapiData.marketplaceId}/products/${asin}`
        });
        const headers: { [key: string]: string } = {
            "Accept": 'application/vnd.com.amazon.api+json; type="lists.list.items.added-product/v1"',
            "Content-Type": 'application/vnd.com.amazon.api+json; type="lists.list.items.add-product.request/v1"',
            "Accept-Language": "en-US", // AAPI will throw a 406 if using a language code it doesn't recognize
            "x-api-csrf-token": aapiData.aapiCsrfToken || "",
            "Origin": `https://www.${requestDomain}`,
        };
        if (aapiData.encryptedSlateToken) {
            headers["x-amzn-encrypted-slate-token"] = aapiData.encryptedSlateToken;
        }
        const responseWithPerf: PerfWrapper<AapiAddToWishlistResponse> = await callNativeHttpRequestBridgeAPIWithPerf({ method: "POST", path, body, headers}, metrics);
        if (debug.isDebugLoggingEnabled) {
            debug.log(`${perfToString(responseWithPerf, true)}`);
        }
        if (responseWithPerf.isError && !retryWithFreshCsrfToken) {
            retryWithFreshCsrfToken = true; // try again with a fresh CSRF token
            continue;
        }
        output = responseWithPerf.isError || !responseWithPerf.result.entity?.status;
        break;
    } while (true);
    return output;
};

type VellaProductResponse = {
    [key: string]: VellaProduct
};

export const fetchVellaProduct = async (asin: string): Promise<VellaProductResponse> => {
    const path = "/kindle-vella/api/v1/product";
    const queryParams = { asin }
    const metrics = newMetricsWithContext("fetchVellaProduct", asin);
    const headers = { "Cache-Control" : "no-cache" };
    const responseWithPerf = await callNativeHttpRequestBridgeAPIWithPerf({ path, queryParams, headers }, metrics);
    if (debug.isDebugLoggingEnabled) {
        debug.log(`${perfToString(responseWithPerf)}`);
    }
    if (responseWithPerf.isError) {
        return Promise.reject(responseWithPerf.error);
    }
    return responseWithPerf.result;
};

export const fetchVellaLikeCount = async (asin: string): Promise<number> => {
    const path = `/kindle-vella/api/v1/social/story/${asin}/likeCount`;
    const metrics = newMetricsWithContext("fetchVellaLikeCount", asin);
    const headers = { "Cache-Control" : "no-cache" };
    return callNativeHttpRequestBridgeAPI({ path, headers }, metrics);
};

export const fetchVellaStoryFollowInfo = async (asin: string): Promise<VellaFollowData> => {
    const path = `/followv2/follow/${asin}_story`;
    const metrics = newMetricsWithContext("fetchVellaStoryFollowInfo", asin);
    const headers = { "Cache-Control" : "no-cache" };
    const queryParams = { ref: "kindle-qv" }
    return callNativeHttpRequestBridgeAPI({ path, queryParams, headers }, metrics);
};

export const followVellaStory = async (asin: string, csrfToken: string): Promise<{entity_id: string, category: string}> => {
    const path = requestDomain && useRequestDomainOnRequests
        ? `https://www.${requestDomain}/followv2/follow`
        : `/followv2/follow`;
    const body = {
        entity_id: asin,
        category: "story",
        ref: "quickview-follow",
        authenticity_token: csrfToken
    };
    const metrics = newMetricsWithContext("followVellaStory", asin);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    return callNativeHttpRequestBridgeAPI({ path, method: "POST", headers, body: JSON.stringify(body) }, metrics);
};

export const unfollowVellaStory = async (asin: string, csrfToken: string): Promise<{entity_id: string, category: string}> => {
    const path = requestDomain && useRequestDomainOnRequests
        ? `https://www.${requestDomain}/followv2/follow/${asin}_story`
        : `/followv2/follow/${asin}_story`;
    const queryParams = { "authenticity_token": csrfToken };
    const metrics = newMetricsWithContext("unfollowVellaStory", asin);
    return callNativeHttpRequestBridgeAPI({ method: 'DELETE', path, queryParams }, metrics);
};

type FetchVellaEpisodesResponse = {
    asin?: string;
    pageNumber?: number;
    nextPageNumber?: number;
    pageSize?: number;
    collectionSize?: number;
    episodes?: [VellaEpisodeData];
};

export const fetchVellaEpisodes = async (asin: string, pageNumber = 1, pageSize = 25): Promise<FetchVellaEpisodesResponse> => {
    const path = `/kindle-vella/api/v1/episodeList`;
    const queryParams = { asin, pageNumber: `${pageNumber}`, pageSize: `${pageSize}` };
    const metrics = newMetricsWithContext("fetchVellaEpisodes", asin);
    const headers = {
        "Cache-Control": "max-age=1800", // 30 minutes = 60s * 30 = 1800s
    };
    return callNativeHttpRequestBridgeAPI({path, headers, queryParams}, metrics);
};

const fetchVellaCsrf = async (): Promise<string> => {
    const path = "/kindle-vella/api/v1/csrf";
    const metrics = newMetricsWithContext("fetchVellaCsrf");
    const headers = { "Cache-Control" : "no-cache" };
    return callNativeHttpRequestBridgeAPI({ path, headers, parseResponseAsJson: false }, metrics);
};

export const grantFreeEpisodeOwnership = async (asin: string) => {
    const path = requestDomain && useRequestDomainOnRequests
        ? `https://www.${requestDomain}/kindle-vella/api/v1/rights/grant-ownership-free`
        : `/kindle-vella/api/v1/rights/grant-ownership-free`;
    const csrf = await fetchVellaCsrf();
    const metrics = newMetricsWithContext("grantFreeEpisodeOwnership", asin);
    const queryParams = { episodeAsin: asin };
    const headers = {
        "anti-csrftoken-a2z": csrf,
    };
    const responseWithPerf: PerfWrapper<any> = await callNativeHttpRequestBridgeAPIWithPerf({ path, method: "POST", headers, queryParams, parseResponseAsJson: false }, metrics);
    if (debug.isDebugLoggingEnabled) {
        debug.log(`${perfToString(responseWithPerf, true)}`);
    }
    return responseWithPerf.isError !== true && responseWithPerf.result.length < 100;
};

// TODO: Maybe move this Datamate cruft into its own module
// GraphQL is annoying/verbose/ugly, so we let you write it with tons of whitespace below, but minify it using some basic rules
// TODO: use the tags/filtering to only get Kindle eligible ASINs (e.g., ebooks) so we don't have to filter out pBooks and such later
// https://code.amazon.com/packages/CustomerCornerReactWebApp/blobs/mainline/--/src/data/filter/filter-tag-id.ts
const getRecsGql = (asin: string, pageSize: number, after: string, tags: string[]) => [" ", ":", "{", "}", "\\[", "]", "\\(", "\\)"].reduce(
    (prev, curr) => prev.replace(new RegExp(`\\s*${curr}\\s*`, "g"), curr.replace(/\\/g, "")),
    `
    {
        getCustomerLibrary {
            bookRecommendations(
                after: "${after}",
                first: ${pageSize},
                asin: "${asin}",
                selectionCriteria: {
                    tags: [${tags.map(it => `"${it}"`).join(", ")}],
                    query: "(bb7e03d170fbb001d6dacd940e50f232)"
                },
                libraryType: OWNED
            ) {
                tags {
                    tag {
                        id
                        name
                        imageURL
                    }
                }
                pageInfo {
                    hasNextPage
                    endCursor
                }
                edges {
                    node {
                        book {
                            product {
                                asin
                                title {
                                    displayString
                                }
                                category {
                                    glProductGroup {
                                        symbol
                                    }
                                }
                                images {
                                    images {
                                        hiRes {
                                            physicalId
                                            extension
                                            height
                                            width
                                        }
                                        lowRes {
                                            physicalId
                                            extension
                                            height
                                            width
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    `
);

export const fetchAsinRecsFromDatamate = async (asin: string, tags: string[]): Promise<PerfWrapper<any>> => {
    const path = requestDomain && useRequestDomainOnRequests
    ? `https://www.${requestDomain}/kindle-reader-api`
    : "/kindle-reader-api";
    const headers = {
        "Content-Type": "application/json",
    };
    const api = "ccGetSingleItemRecommendation";
    const queryParams = {
        operationName: api,
        query: `query ${api} ${getRecsGql(asin, 25, "", tags)}`,
    };
    const metrics = newMetricsWithContext("fetchAsinRecsFromDatamate", asin);
    return callNativeHttpRequestBridgeAPIWithPerf({ path, queryParams, headers }, metrics);
};

export const fetchAudibleConditionsOfUseAjax = async (locale: string): Promise<string> => {
    const path = `/hz/audible/ajax/audible-terms-and-conditions-ajax.html`;
    const queryParams = { _encoding: "UTF8", type: "cou", language: locale };
    const metrics = newMetricsWithContext("fetchAudibleConditionsOfUseAjax");
    const headers = {
        "Cache-Control": "max-age=1800", // 30 minutes = 60s * 30 = 1800s
    };
    return callNativeHttpRequestBridgeAPI({ path, headers, queryParams, parseResponseAsJson: false }, metrics);
};
