import React from "react";
import DeviceContext from "src/contexts/DeviceContext";
import BottomSheet, { BottomSheetState } from "src/components/BottomSheet/BottomSheet";
import { NativeAPI } from "src/utils/deviceUtils";
import styles from "./QuickView.module.scss";
import { getBbMetadata, getQvMetadata, isSponsoredAsin } from "src/utils/asinMetadataUtils";
import WorkflowContext, { QuickViewIndexUpdateCallback, QuickViewIndexUpdateState } from "src/contexts/WorkflowContext";
import AddToList from "../AddToList/AddToList";
import BuyBox from "../BuyBox/BuyBox"
import ReturnAndBorrow from "../ReturnAndBorrow/ReturnAndBorrow";
import AcquisitionManager from "../AcquisitionManager/AcquisitionManager";
import AudioPlayerContext from "src/contexts/AudioPlayerContext";
import ChangeoverToast from "../ChangeoverToast/ChangeoverToast";
import DebugGooey from "../DebugGooey/DebugGooey";
import debug from "src/utils/debugUtils";
import QuickViewSlide from "../QuickViewSlide/QuickViewSlide";
import { disableMetrics, elapsed, flushBatchedMetrics, Metrics, newMetricsWithContext, recordBehavioralMetric} from "src/utils/metricsUtils";
import FollowManager from "../Vella/FollowManager";
import { getUrlHashParams } from "src/utils/urlUtils";

// When no ASIN metadata is provided, set initial state to this dummy placeholder value
// so that the QuickViewSlide can be rendered and all typical assets cached (e.g. translations)
const CACHE_WARMER_DUMMY_ASIN: QuickViewAsinMetadata = { asin: "" };
// An invalid ASIN, used to show a loading card at the end of the quickview results while more results are loading.
const LOAD_MORE_RESULTS_ASIN: QuickViewAsinMetadata = { asin: "LOAD_MORE_RESULTS_ASIN" };

type PropTypes = object;

type State = {
    initialIndexes: number[];
    asins: QuickViewAsinMetadata[][];
    asinNavStackPosition: number;
    bottomSheetStates: BottomSheetState[];
    subBottomSheetState: BottomSheetState;
    callbacks: QuickViewIndexUpdateCallback[]
    addToListAsin?: string;
    buyBoxQvMetadata?: QuickViewAsinMetadata;
    borrowLimitData?: BorrowLimitData;
    subBottomSheetDismissCallback?: VoidFunction;
    audioSrc?: string;
    infoMessage?: string;
    showDebugGooey?: boolean;
    debugGooeyMode?: string;
    isBottomSheetDragging: boolean;
    hasOpened: boolean[];
    hasMoreResults?: boolean;
    pagingToken?: string;
};

class QuickView extends React.PureComponent<PropTypes, State> {
    static contextType = DeviceContext;
    declare context: React.ContextType<typeof DeviceContext>;

    scrollerDivs: React.RefObject<HTMLUListElement>[];
    isFetching = false;
    activeIndexes: number[] = [-1];
    cardsSwiped = 0;
    metrics: Metrics = newMetricsWithContext("QuickView");
    shouldSyncLibraryOnDismiss = false;
    timeInQuickViewMS: number;
    slideFocusedTimeMS: number;
    scrollFixupTimerId: ReturnType<typeof setTimeout> | undefined;
    isUserTouching = false;
    viewDepth = 0;

    constructor(props: PropTypes) {
        super(props);
        this.timeInQuickViewMS = Date.now();
        this.slideFocusedTimeMS = Date.now();
        this.state = this.createInitialState();
        this.scrollerDivs = [React.createRef<HTMLUListElement>()];
        this.isFetching = false;
    }

    /**
     * Allow initial state values to be pre-populated from `window.quickViewData` (preferred) and/or the URL hash params.
     * On Android, if the window.quickViewData object isn't present, self-inject it via a call to `window.QuickView.getQuickViewData`.
     * If no (valid) URL hash or injected metadata present, returns `State` with default parameter values.
     */
    createInitialState(): State {
        const urlHashParams = getUrlHashParams();
        const injectedAsinMetadata = window.quickViewData?.asins;
        const hashParamAsinMetadata: QuickViewAsinMetadata[] = urlHashParams.asins?.split(",").map((asin) => {
            return {
                asin: asin,
            };
        });
        const asinsMetadata = injectedAsinMetadata ?? hashParamAsinMetadata ?? [CACHE_WARMER_DUMMY_ASIN];

        // We're in the cache warmer state, don't record any more metrics
        if (asinsMetadata.length === 1 && asinsMetadata[0].asin === CACHE_WARMER_DUMMY_ASIN.asin) {
            disableMetrics();
        }

        const injectedInitialIndex = window.quickViewData?.initialIndex;
        const hashParamInitialIndex = Number(urlHashParams.initialIndex);
        let initialIndex: number = (injectedInitialIndex ?? hashParamInitialIndex) || 0;
        const injectedHasMoreResults = window.quickViewData?.hasMoreResults;
        const injectedPagingToken = window.quickViewData?.pagingToken;
        const sponsoredAsinsCount = asinsMetadata.filter(isSponsoredAsin).length;

        const m = this.metrics;

        m.recordOperationalMetric("sponsoredAsins.count", sponsoredAsinsCount);
        m.recordOperationalMetric("sponsoredAsins.percentage", sponsoredAsinsCount / asinsMetadata.length);
        m.recordMetric("initialIndex", initialIndex);
        m.recordMetric("initialAsinsCount", asinsMetadata.length);

        const width = (window.visualViewport || window.screen).width;
        const height = (window.visualViewport || window.screen).height;
        m.recordMetric("display.width", width);
        m.recordMetric("display.height", height);
        m.recordMetric("display.aspectRatio", width / height);
        m.recordMetric("display.isPortrait", width < height ? 1 : 0);

        const element = document.getElementById("font-size-checker");
        if (element) {
            const fontSize = parseFloat(window.getComputedStyle(element, null).getPropertyValue("font-size"));
            m.recordMetric("display.fontSize", fontSize);
        }

        // Time between QV launcher invocation (window.quickViewData.startTime) and QV webview code starting (window.qv.htmlStartTime)
        m.recordMetric("launcherTo.MarkupStart", elapsed(window.quickViewData?.startTime, window.qv?.htmlStartTime));
        m.recordTimeFromStart("JsStart", window.qv?.jsStartTime);

        let asins = asinsMetadata;
        if (debug.get("limitToOneCard")) {
            asins = asins.slice(initialIndex, initialIndex + 1);
            initialIndex = 0;
        } else if (debug.get("limitToThreeCards")) {
            const first = initialIndex > 0 ? initialIndex - 1 : initialIndex;
            initialIndex = initialIndex > 0 ? 1 : 0;
            asins = asins.slice(first, first + 3);
        }
        this.activeIndexes = [initialIndex];
        this.viewDepth = (window.quickViewData?.viewDepth ?? -1) + 1;

        if (window.quickViewData?.hasMoreResults) { injectedAsinMetadata?.push(LOAD_MORE_RESULTS_ASIN); }

        const qvUpdateCallback = (index: number, state: QuickViewIndexUpdateState) => {
            if (state === QuickViewIndexUpdateState.UPDATE) {
                NativeAPI.updateQuickViewPosition(index);
            }
        };

        return {
            initialIndexes: [initialIndex],
            asinNavStackPosition: 0,
            asins: [asins],
            bottomSheetStates: [asinsMetadata.length > 0 ? BottomSheetState.FULLSCREEN : BottomSheetState.DISMISSED],
            subBottomSheetState: BottomSheetState.DISMISSED,
            isBottomSheetDragging: false,
            hasOpened: [false],
            hasMoreResults: injectedHasMoreResults,
            pagingToken: injectedPagingToken,
            callbacks: [qvUpdateCallback],
        };
    }

    getAsinsAsStrings = () => this.currAsins().map((it) => it.asin);

    afterOpen = () => {
        if (this.state.asinNavStackPosition === 0) {
            const now = Date.now();
            this.metrics.recordTimeFromStart("BottomSheetAfterOpen", now);
            window.onBackPressed = () => {
                if (this.state.subBottomSheetState === BottomSheetState.FULLSCREEN) {
                    this.dismissSubBottomsheet();
                } else {
                    this.dismissBottomSheet();
                }
                return true;
            }
        }

        const newHasOpened = Array.from(this.state.hasOpened);
        newHasOpened[this.state.asinNavStackPosition] = true;

        this.setState({
            hasOpened: newHasOpened,
        });
    }

    dismissBottomSheet = () => {
        debug.log("dismissBottomSheet");
        if (this.state.asinNavStackPosition > 0) {
            this.state.callbacks[this.state.asinNavStackPosition]?.(this.activeIndexes[this.state.asinNavStackPosition], QuickViewIndexUpdateState.UPDATE);
        }
        const newBottomSheetStates = Array.from(this.state.bottomSheetStates);
        newBottomSheetStates[this.state.asinNavStackPosition] = BottomSheetState.DISMISSED;
        this.setState({
            bottomSheetStates: newBottomSheetStates,
            borrowLimitData: undefined,
            addToListAsin: undefined,
            showDebugGooey: false,
            buyBoxQvMetadata: undefined,
            subBottomSheetDismissCallback: undefined,
        });
    };

    getCurrentIndexFromScrollOffset = (): number => {
        const scrollLeft = this.scrollerDivs[this.state.asinNavStackPosition]?.current?.scrollLeft ?? 0;
        const scrollWidth = this.scrollerDivs[this.state.asinNavStackPosition]?.current?.scrollWidth ?? 0;
        const cardSize = scrollWidth / this.currAsins().length;
        return Math.min(Math.ceil(scrollLeft / cardSize), this.currAsins().length - 1);
    }

    scrollFixupCallback = () => {
        const scrollerDiv = this.scrollerDivs[this.state.asinNavStackPosition]?.current;
        // Correct the sitution where the horizontal scroller is stuck between slides.
        // Scrolling to the current position will recenter the current slide with scroll snapping.
        if (!this.isUserTouching && scrollerDiv && !debug.get("disableAndroidScrollFixup")) {
            scrollerDiv.scrollTo({
                left: scrollerDiv.scrollLeft,
                behavior: "smooth"
            });
        }
    }

    scheduleFixup = () => {
        if (!this.context.device.isIOS) {
            clearTimeout(this.scrollFixupTimerId);
            this.scrollFixupTimerId = setTimeout(this.scrollFixupCallback, 750);
        }
    }

    fetchBooks = () => {
        const maybePromise = NativeAPI.fetchMoreData(this.state.pagingToken)
            ?.then( (pagingResult) => {
                if (pagingResult.asins.length === 0) {
                    if (pagingResult.hasMoreResults) {
                        setTimeout(this.fetchBooks, 250);
                    } else {
                        this.isFetching = false;
                        const newAsins = Array.from(this.state.asins);
                        newAsins[this.state.asinNavStackPosition] = this.currAsins().slice(0, this.currAsins().length-1);
                        this.setState({
                            hasMoreResults: false,
                            asins: newAsins,
                        });
                    }
                    return;
                }
                this.isFetching = false;
                const newAsins = Array.from(this.state.asins);
                newAsins[this.state.asinNavStackPosition].splice(this.currAsins().length-1, pagingResult.hasMoreResults ? 0 : 1, ...pagingResult.asins);
                this.setState({
                    hasMoreResults: pagingResult.hasMoreResults,
                    pagingToken: pagingResult.pagingToken,
                    asins: newAsins
                });
            });

            // This covers the case that "hasMoreResults" was set to true when the quickviewData was injected,
            // But the native bridge to update quickview with new data was not present.
        if (maybePromise === undefined) {
            this.isFetching = false;
            const newAsins = Array.from(this.state.asins);
            newAsins[this.state.asinNavStackPosition] = this.currAsins().slice(0, this.currAsins().length-1);
            this.setState({
                hasMoreResults: false,
                asins: newAsins,
            });
        }
    };


    currAsins = () => this.state.asins[this.state.asinNavStackPosition];

    handleScroll = () => {
        if (!this.state.isBottomSheetDragging && this.state.hasOpened[this.state.asinNavStackPosition] && this.scrollerDivs[this.state.asinNavStackPosition]?.current) {
            const currentIndex = this.getCurrentIndexFromScrollOffset();
            const currentStackActiveIndex = this.activeIndexes[this.state.asinNavStackPosition];
            if (currentIndex !== currentStackActiveIndex) {
                if (currentIndex >= this.currAsins().length-3 && this.state.hasMoreResults && !this.isFetching) {
                    this.isFetching = true;
                    this.fetchBooks();
                }
                recordBehavioralMetric({ namespace: "QuickView", qv_asin: this.currAsins()[currentStackActiveIndex ?? -1]?.asin }, "timeOnSlideMs", Date.now() - this.slideFocusedTimeMS);
                this.slideFocusedTimeMS = Date.now();
                this.activeIndexes[this.state.asinNavStackPosition] = currentIndex;
                this.cardsSwiped += 1;
                this.state.callbacks[this.state.asinNavStackPosition]?.(currentIndex, QuickViewIndexUpdateState.UPDATE);
            }
        }
    };

    handleClose = (args: { closeAll: boolean }) => {
        debug.log("handleClose");
        if (this.shouldSyncLibraryOnDismiss) {
            NativeAPI.syncLibrary();
        }
        const index = this.activeIndexes[this.state.asinNavStackPosition] ?? this.getCurrentIndexFromScrollOffset();
        if (this.state.audioSrc !== undefined) {
            this.setState({
                audioSrc: undefined,
            });
        }
        this.metrics.recordBehavioralMetric("cardsSwiped", this.cardsSwiped);
        this.metrics.recordBehavioralMetric("timeInQuickViewMs", Date.now() - this.timeInQuickViewMS);
        recordBehavioralMetric({ namespace: "QuickView", qv_asin: this.currAsins()[index]?.asin }, "timeOnSlideMs", Date.now() - this.slideFocusedTimeMS);
        if (this.viewDepth) {
            this.metrics.recordOperationalMetric("viewDepth", this.viewDepth);
            if (args.closeAll) {
                this.metrics.recordOperationalMetric("viewDepthAtCloseAll", this.viewDepth);
            } else if (this.viewDepth > 1) {
                this.metrics.recordOperationalMetric("viewDepthAtBypass", this.viewDepth);
            }
        }
        flushBatchedMetrics();

        const closingIndex = this.state.asinNavStackPosition > 0
            ? this.state.initialIndexes[0]
            : index;

        args.closeAll
            ? NativeAPI.popToRoot()
            : NativeAPI.dismissQuickView(closingIndex);
    };

    closeAll = (event: React.UIEvent) => {
        event.stopPropagation();
        this.handleClose({ closeAll: true })
    };

    openQv = (asins: QuickViewAsinMetadata[], initialIndex: number, callback: QuickViewIndexUpdateCallback) => {
        debug.log(asins);
        debug.log(initialIndex);
        const openInNewWebview = debug.get("enableMoreLikeThisInNewWebview");
        if (openInNewWebview) {
            return NativeAPI.launchQuickView(asins.map(it => it.asin), initialIndex);
        }
        const newStackIndex = this.state.asinNavStackPosition + 1;
        const newAsins = Array.from(this.state.asins);
        newAsins[newStackIndex] = asins;
        const newIndexes = Array.from(this.state.initialIndexes);
        newIndexes[newStackIndex] = initialIndex;
        this.activeIndexes[newStackIndex] = initialIndex;
 
        const newCallbacks = Array.from(this.state.callbacks);
        newCallbacks[newStackIndex] = callback;
 
        this.scrollerDivs.push(React.createRef<HTMLUListElement>());

        const newBottomSheetStates = Array.from(this.state.bottomSheetStates);
        newBottomSheetStates[newStackIndex] = BottomSheetState.FULLSCREEN;
        this.setState({
            asins: newAsins,
            asinNavStackPosition: newStackIndex,
            initialIndexes: newIndexes,
            bottomSheetStates: newBottomSheetStates,
            callbacks: newCallbacks,
        });
    };

    dismissNavStack = () => {
        debug.log("dismissNavStack");
        const newAsins = Array.from(this.state.asins);
        const newIndexes = Array.from(this.state.initialIndexes);
        const newBottomSheetStates = Array.from(this.state.bottomSheetStates);
        newAsins.pop();
        newIndexes.pop();
        newBottomSheetStates.pop();
        let newNavIndex = this.state.asinNavStackPosition - 1;
        if (newNavIndex < 0) {
            newNavIndex = 0;
        }
        debug.log(`newNavIndex: ${newNavIndex}`);
        this.setState({
            asins: newAsins,
            asinNavStackPosition: newNavIndex,
            initialIndexes: newIndexes,
            bottomSheetStates: newBottomSheetStates,
        })
    };

    handleBottomSheetDismiss = () => {
        debug.error("handleBottomSheetDismiss");
        if (this.state.asinNavStackPosition > 0) {
            this.state.callbacks[this.state.asinNavStackPosition]?.(this.activeIndexes[this.state.asinNavStackPosition], QuickViewIndexUpdateState.CLOSED);
        }
        if (this.state.asinNavStackPosition > 0) {
            this.dismissNavStack();
        } else {
            this.handleClose({ closeAll: false })
        }
    };

    setAddToListAsin = (asin: string, callback: VoidFunction) => this.setState({
        addToListAsin: asin, subBottomSheetDismissCallback: callback, subBottomSheetState: BottomSheetState.FULLSCREEN,
    });

    clearAddToListAsin = () => {
        const callback = this.state.subBottomSheetDismissCallback;
        this.setState({ addToListAsin: "", subBottomSheetDismissCallback: undefined });
        callback?.();
    }

    setBuyBoxAsin = (metadata: QuickViewAsinMetadata, callback: VoidFunction) => this.setState({
        buyBoxQvMetadata: metadata, subBottomSheetDismissCallback: callback, subBottomSheetState: BottomSheetState.FULLSCREEN,
    });

    clearBuyBoxAsin = () => {
        const callback = this.state.subBottomSheetDismissCallback;
        this.setState({ buyBoxQvMetadata: undefined, subBottomSheetDismissCallback: undefined });
        callback?.();
    }

    setBorrowLimitData = (data: BorrowLimitData, callback?: VoidFunction) => this.setState({
        borrowLimitData: data, subBottomSheetDismissCallback: callback, subBottomSheetState: BottomSheetState.FULLSCREEN,
    });

    clearBorrowLimitData = () => {
        const callback = this.state.subBottomSheetDismissCallback;
        this.setState({ borrowLimitData: undefined, subBottomSheetDismissCallback: undefined});
        callback?.();
    }

    dismissSubBottomsheet = () => {
        this.setState({ subBottomSheetState: BottomSheetState.DISMISSED });
    }

    setAudioSrc = (src: string) => this.setState({ audioSrc: src });

    setInfoMessage = (data: string) => this.setState({ infoMessage: data });

    clearInfoMessage = () => this.setState({ infoMessage: undefined });

    showDebugGooey = (mode = "qv") => this.setState({ showDebugGooey: true, debugGooeyMode: mode, subBottomSheetState: BottomSheetState.FULLSCREEN, });

    hideDebugGooey = () => this.setState({ showDebugGooey: false });

    startBottomSheetDragging = () => this.setState({ isBottomSheetDragging: true });

    startTouching = () => this.isUserTouching = true;

    stopTouching = () => {
        this.isUserTouching = false;
        this.scheduleFixup();
    }

    endBottomSheetDragging = () => this.setState({ isBottomSheetDragging: false });

    shouldSyncLibraryCallback = () => this.shouldSyncLibraryOnDismiss = true;

    render() {
        const hasSubBottomSheet = !!(this.state.borrowLimitData || this.state.addToListAsin || this.state.showDebugGooey || this.state.buyBoxQvMetadata);

        return (
            <WorkflowContext.Provider
                value={{
                    addToList: this.setAddToListAsin,
                    showBuyBox: this.setBuyBoxAsin,
                    borrowLimit: this.setBorrowLimitData,
                    infoMessage: this.setInfoMessage,
                    showDebugGooey: this.showDebugGooey,
                    openQv: this.openQv,
                }}
            >
                <AudioPlayerContext.Provider value={{ src: this.state.audioSrc, setSrc: this.setAudioSrc }}>
                    <AcquisitionManager shouldSyncLibraryCallback={this.shouldSyncLibraryCallback} asins={this.getAsinsAsStrings()}>
                    <FollowManager>
                        {this.state.asins.map((asins, asinsIndex) => (
                            <BottomSheet
                                key={`asins_at_index_${asinsIndex}`}
                                state={this.state.bottomSheetStates[asinsIndex]}
                                afterDismiss={this.handleBottomSheetDismiss}
                                afterOpen={this.afterOpen}
                                onDragStart={this.startBottomSheetDragging}
                                onDragEnd={this.endBottomSheetDragging}
                            >
                                <ul
                                    ref={this.scrollerDivs[asinsIndex]}
                                    className={`${styles.horizontalScroller} ${asins.length === 1 ? styles.singleAsinMode : ""} ${this.state.isBottomSheetDragging ? styles.disableScrolling : ""}`}
                                    onScroll={this.handleScroll}
                                    aria-hidden={hasSubBottomSheet}
                                    onTouchStart={this.startTouching}
                                    onTouchCancel={this.stopTouching}
                                    onTouchEnd={this.stopTouching}
                                >
                                    {asins.map((item, index) =>
                                        <QuickViewSlide
                                            key={item.asin + index}
                                            item={item}
                                            isInitialIndex={index === this.state.initialIndexes[asinsIndex]}
                                            getter={getQvMetadata}
                                            dismiss={this.dismissBottomSheet}
                                            closeAll={this.viewDepth > 1 || asinsIndex > 0 ? this.closeAll : undefined}
                                            disableScrolling={this.state.isBottomSheetDragging}
                                            parentContainer={this.scrollerDivs[asinsIndex]}
                                            index={index}
                                            lastIndex={asins.length - 1}
                                            canLoad={this.state.hasOpened[asinsIndex]}
                                        />
                                    )}
                                </ul>
                            </BottomSheet>
                        ))};
                        {this.state.borrowLimitData && this.state.subBottomSheetDismissCallback && (
                            <BottomSheet state={this.state.subBottomSheetState} afterDismiss={this.clearBorrowLimitData} limitWidth>
                                <ReturnAndBorrow
                                    data={this.state.borrowLimitData}
                                    dismiss={this.dismissSubBottomsheet}
                                    callback={this.state.subBottomSheetDismissCallback}
                                />
                            </BottomSheet>
                        )}
                        {this.state.buyBoxQvMetadata && (
                            <BottomSheet state={this.state.subBottomSheetState} afterDismiss={this.clearBuyBoxAsin} limitWidth>
                                <BuyBox data={this.state.buyBoxQvMetadata} getter={getBbMetadata} dismiss={this.dismissSubBottomsheet} />
                            </BottomSheet>
                        )}
                    </FollowManager>
                    </AcquisitionManager>
                </AudioPlayerContext.Provider>
                {this.state.addToListAsin && (
                    <BottomSheet state={this.state.subBottomSheetState} afterDismiss={this.clearAddToListAsin} limitWidth>
                        <AddToList asin={this.state.addToListAsin} dismiss={this.dismissSubBottomsheet} />
                    </BottomSheet>
                )}
                {this.state.infoMessage && (
                    <ChangeoverToast message={this.state.infoMessage} dismiss={this.clearInfoMessage} />
                )}
                {this.state.showDebugGooey && (
                    <BottomSheet state={this.state.subBottomSheetState} afterDismiss={this.hideDebugGooey} limitWidth>
                        <DebugGooey dismiss={this.dismissSubBottomsheet} mode={this.state.debugGooeyMode} />
                    </BottomSheet>
                )}
            </WorkflowContext.Provider>
        );
    }
}

export default QuickView;
